export const Ticket = ({ fill = "#0bb783" }) => {
    return (
        <svg
            id="vuesax_bulk_receipt-2"
            // data-name="vuesax/bulk/receipt-2"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
        >
            <g id="receipt-2">
                <path id="Vector" d="M0,0H25V25H0Z" fill="none" opacity="0" />
                <path
                    id="Vector-2"
                    // data-name="Vector"
                    d="M17.708,5.25V15.583a8.542,8.542,0,0,1-.521,3.51.079.079,0,0,1-.021.042,1.085,1.085,0,0,1-.885.438,2.918,2.918,0,0,1-1.937-1.135,1.841,1.841,0,0,0-2.917.156l-1.052,1.4a1.906,1.906,0,0,1-1.521.844,1.906,1.906,0,0,1-1.521-.844L6.271,18.583a1.832,1.832,0,0,0-2.9-.156l-.01.01c-1.177,1.26-2.219,1.448-2.823.7a.079.079,0,0,1-.021-.042A8.542,8.542,0,0,1,0,15.583V5.25A8.542,8.542,0,0,1,.521,1.74c0-.01,0-.021.021-.031.594-.76,1.646-.573,2.823.688l.01.01a1.832,1.832,0,0,0,2.9-.156L7.333.844A1.906,1.906,0,0,1,8.854,0a1.906,1.906,0,0,1,1.521.844l1.052,1.4a1.841,1.841,0,0,0,2.917.156A2.918,2.918,0,0,1,16.281,1.26a1.1,1.1,0,0,1,.885.448c.021.01.021.021.021.031A8.542,8.542,0,0,1,17.708,5.25Z"
                    transform="translate(3.125 2.083)"
                    fill={fill}
                    opacity="0.4"
                />
                <g id="Group" transform="translate(7.552 9.896)">
                    <path
                        id="Vector-3"
                        // data-name="Vector"
                        d="M9.115,1.563H.781A.787.787,0,0,1,0,.781.787.787,0,0,1,.781,0H9.115A.787.787,0,0,1,9.9.781.787.787,0,0,1,9.115,1.563Z"
                        fill={fill}
                    />
                </g>
                <g
                    id="Group-2"
                    // data-name="Group"
                    transform="translate(7.552 13.542)"
                >
                    <path
                        id="Vector-4"
                        // data-name="Vector"
                        d="M7.031,1.563H.781A.787.787,0,0,1,0,.781.787.787,0,0,1,.781,0h6.25a.787.787,0,0,1,.781.781A.787.787,0,0,1,7.031,1.563Z"
                        fill={fill}
                    />
                </g>
            </g>
        </svg>
    )
}
