import { DatePicker as $DatePicker } from "antd"
import { Field } from "formik"
import "./style.scss"
import { useTranslation } from "react-i18next"

export const DateRangePicker = ({
    name,
    disableDate,
    disableTime,
    onChange,
    label,
    required,
}) => {
    const { t } = useTranslation("/Components/ns")

    return (
        <Field name={name}>
            {({ field, meta, form: { values, setFieldValue } }) => (
                <div className="w-full">
                    {label && (
                        <label
                            htmlFor={name}
                            className="mb-[16px] text-white text-[14px]"
                        >
                            {t("dateRange")}{" "}
                            {required && (
                                <span className="text-red-500">*</span>
                            )}
                        </label>
                    )}
                    <$DatePicker.RangePicker
                        onChange={(date) => {
                            setFieldValue(name, date)
                            if (onChange) {
                                onChange(date)
                            }
                        }}
                        value={values[name]}
                        dropdownClassName="custom-date-picker-dd"
                        format="MM/DD/YYYY"
                        separator={<></>}
                        className="custom-date-picker w-full h-[52px] bg-[#171723] rounded-[8px] text-[#92928F] flex items-center justify-between px-[16px]"
                    />
                    {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                    )}
                </div>
            )}
        </Field>
    )
}
