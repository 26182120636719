export const Reply = ({ fill = "#0bb783" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g
                id="vuesax_bulk_send-2"
                data-name="vuesax/bulk/send-2"
                transform="translate(-300 -316)"
            >
                <g id="send-2">
                    <path
                        id="Vector"
                        d="M4.554,3.407,13.574.4c4.05-1.35,6.25.86,4.91,4.91l-3.01,9.02c-2.02,6.07-5.34,6.07-7.36,0l-.89-2.68-2.68-.89C-1.516,8.747-1.516,5.437,4.554,3.407Z"
                        transform="translate(302.556 318.553)"
                        fill="#3699ff"
                        opacity="0.4"
                    />
                    <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M0,3.82,3.81,0"
                        transform="translate(312.12 323.81)"
                        fill="#3699ff"
                    />
                    <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M.747,5.318a.742.742,0,0,1-.53-.22.754.754,0,0,1,0-1.06l3.8-3.82a.75.75,0,0,1,1.06,1.06L1.277,5.1A.786.786,0,0,1,.747,5.318Z"
                        transform="translate(311.372 323.063)"
                        fill="#3699ff"
                    />
                    <path
                        id="Vector-4"
                        data-name="Vector"
                        d="M0,0H24V24H0Z"
                        transform="translate(300 316)"
                        fill="none"
                        opacity="0"
                    />
                </g>
            </g>
        </svg>
    )
}
