import { getConfig } from "lib"

const maintenanceSettingsConfig = (action) =>
    getConfig({ module: "MaintenanceSettings", action })

// Get Maintenance Settings
export const getMaintenanceSettingsConfig = (tenant) => ({
    url: `/api/maintenance/maintenancemode/${tenant}`,
    config: maintenanceSettingsConfig("View"),
})

// Post Maintenance Settings
export const postMaintenanceSettingsConfig = () => ({
    url: `/api/maintenance`,
    config: maintenanceSettingsConfig("Update"),
})
