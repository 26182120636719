import {
    getProductsConfig,
    getProductsByIDConfig,
    updateProductByIDConfig,
    createProductConfig,
    axios,
    getError,
} from "lib"
import {
    cancelProductAtEndByIDConfig,
    cancelProductByIDConfig,
    deleteCancelledProductsConfig,
    deleteProductByIDConfig,
    getCancelledProductsConfig,
} from "lib/requests"
import { toast } from "react-toastify"

// Get All Products
export const getProductsCall = async () => {
    try {
        const res = await axios.post(
            getProductsConfig().url,
            {
                advancedSearch: {
                    fields: [""],
                    keyword: "",
                },
                keyword: "",
                pageNumber: 0,
                pageSize: 0,
                orderBy: [""],
            },
            getProductsConfig().config
        )
        return res
    } catch (error) {
        console.log(error)
    }
}

// Get Cancelled Products
export const getCancelledProductsCall = async () => {
    try {
        const res = await axios.post(
            getCancelledProductsConfig().url,
            {
                advancedSearch: {
                    fields: [""],
                    keyword: "",
                },
                keyword: "",
                pageNumber: 0,
                pageSize: 0,
                orderBy: [""],
            },
            getProductsConfig().config
        )
        return res
    } catch (error) {
        console.log(error)
    }
}
export const cancelRequestAtTheEnd = async (id, sendEmail) => {
    try {
        const { url, config } = cancelProductAtEndByIDConfig(id, sendEmail)
        const res = await axios.put(url, id, config)

        if (res.status === 200) {
            const { url, config } = getProductsByIDConfig(id)
            const res = await axios.get(url, config)

            toast.success("Cancel at end of billing period successful!")
        }
    } catch (e) {
        toast.error(getError(e))
    }
}
export const cancelProduct = async (id, sendEmail) => {
    try {
        const { url, config } = cancelProductByIDConfig(id, sendEmail)
        const res = await axios.put(url, id, config)

        if (res.status === 200) {
            const { url, config } = getProductsByIDConfig(id)
            const res = await axios.get(url, config)

            toast.success("Cancellation request submitted successful!")
        }
    } catch (e) {
        toast.error(getError(e))
    }
}

// Delete Cancelled Products
export const deleteCancelledProducts = async (id) => {
    try {
        const res = await axios.put(
            deleteCancelledProductsConfig(id).url,
            getProductsConfig().config
        )
        return res
    } catch (e) {
        console.log(e)
    }
}

// Get Products by Client ID
export const getProductsByClientIDCall = async (clientId, keyword, pageNumber, pageSize) => {
    try {
        const res = await axios.post(
            getProductsConfig().url,
            {
                advancedSearch: {
                    fields: ["assignedToClientId"],
                    keyword: clientId,
                },
                keyword: keyword || "",
                pageNumber: pageNumber || 0,
                pageSize: pageSize || 0,
                orderBy: [""],
            },
            getProductsConfig().config
        )
        return res
    } catch (error) {
        console.log(error)
    }
}

// Get Products By ID
export const getProductsByIDCall = async (id) => {
    try {
        const { url, config } = getProductsByIDConfig(id)
        const res = await axios.get(url, config)
        return res
    } catch (error) {
        console.log(error)
    }
}

// Update Product By ID
export const updateProductsByIDCall = async (id, data) => {
    try {
        const res = await axios.put(updateProductByIDConfig(id).url, data)
        return res
    } catch (error) {
        console.log(error)
    }
}

// Create Product Call
export const createProductCall = async (data) => {
    try {
        const res = await axios.post(createProductConfig().url, data)
        return res
    } catch (error) {
        console.log(error)
    }
}

// Delete Product By ID
export const deleteProductByIDCall = async (id) => {
    try {
        const res = await axios.delete(deleteProductByIDConfig(id).url)
        return res
    } catch (error) {
        console.log(error)
    }
}
