import { useState, useEffect } from "react"
import { Modal as BSModal } from "react-bootstrap"
import "./Modal.styles.scss"
import { Button, Checkbox } from "antd"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"

export function Modal({
    show,
    setShow,
    heading,
    loading,
    handleSubmit,
    permissions = [
        {
            name: "ModuleManagement",
            permissionDetail: {
                Create: false,
                View: true,
                Search: true,
                Remove: true,
                Update: true,
            },
        },
    ],
    submitText,
    cancelButtonText,
    handleCancel,
}) {
    const [permissionsState, setPermissionsState] = useState([])
    const { t } = useTranslation("/Components/ns")

    const handleClose = () => {
        setShow(false)
    }

    useEffect(() => {
        setPermissionsState(permissions)
    }, [permissions])

    return (
        <BSModal
            show={show}
            onHide={handleClose}
            className={`custom-modal custom-modal__crud`}
        >
            <BSModal.Body className="modal__bg">
                <div className="modal__header">
                    <h3>{heading}</h3>
                </div>
                <div className="modal__divider" />
                <div className="modal__body">
                    <div className="modal__form">
                        {permissionsState.map(
                            (
                                {
                                    name,
                                    permissionDetail: {
                                        Create,
                                        View,
                                        Search,
                                        Update,
                                        Remove,
                                    },
                                },
                                index
                            ) => {
                                return (
                                    <Fragment key={name}>
                                        <div
                                            className="modal__form-el"
                                            key={name}
                                        >
                                            <div className="modal__form-el-checkbox">
                                                <div className="modal__form-el-checkbox-container">
                                                    <div className="modal__form-el-checkbox-container-label">
                                                        {name}
                                                    </div>
                                                    <div className="modal__form-el-checkbox-container-group">
                                                        <Checkbox
                                                            disabled={loading}
                                                            onChange={(e) => {
                                                                const newPermissions =
                                                                    permissionsState.map(
                                                                        (
                                                                            permission
                                                                        ) => {
                                                                            return permission?.name ===
                                                                                name
                                                                                ? {
                                                                                      ...permission,
                                                                                      permissionDetail:
                                                                                          {
                                                                                              Create: e
                                                                                                  .target
                                                                                                  .checked,
                                                                                              Search: e
                                                                                                  .target
                                                                                                  .checked,
                                                                                              View: e
                                                                                                  .target
                                                                                                  .checked,
                                                                                              Update: e
                                                                                                  .target
                                                                                                  .checked,
                                                                                              Remove: e
                                                                                                  .target
                                                                                                  .checked,
                                                                                          },
                                                                                  }
                                                                                : permission
                                                                        }
                                                                    )
                                                                setPermissionsState(
                                                                    newPermissions
                                                                )
                                                            }}
                                                            checked={
                                                                Create &&
                                                                View &&
                                                                Search &&
                                                                Update &&
                                                                Remove
                                                            }
                                                        >
                                                            <p className="modal__form-el-checkbox-container-label">
                                                                {t("all")}
                                                            </p>
                                                        </Checkbox>
                                                        <div
                                                            key={name}
                                                            className="modal__form-el-checkbox-container-el"
                                                        >
                                                            <Checkbox
                                                                disabled={
                                                                    loading
                                                                }
                                                                checked={Create}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const newPermissions =
                                                                        permissionsState.map(
                                                                            (
                                                                                permission
                                                                            ) => {
                                                                                return permission?.name ===
                                                                                    name
                                                                                    ? {
                                                                                          ...permission,
                                                                                          permissionDetail:
                                                                                              {
                                                                                                  ...permission?.permissionDetail,
                                                                                                  Create: e
                                                                                                      .target
                                                                                                      .checked,
                                                                                              },
                                                                                      }
                                                                                    : permission
                                                                            }
                                                                        )
                                                                    // console.log(newPermissions);
                                                                    setPermissionsState(
                                                                        newPermissions
                                                                    )
                                                                }}
                                                            >
                                                                <p className="modal__form-el-checkbox-container-label">
                                                                    {t(
                                                                        "create"
                                                                    )}
                                                                </p>
                                                            </Checkbox>
                                                            <Checkbox
                                                                disabled={
                                                                    loading
                                                                }
                                                                checked={View}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const newPermissions =
                                                                        permissionsState.map(
                                                                            (
                                                                                permission
                                                                            ) => {
                                                                                return permission?.name ===
                                                                                    name
                                                                                    ? {
                                                                                          ...permission,
                                                                                          permissionDetail:
                                                                                              {
                                                                                                  ...permission?.permissionDetail,
                                                                                                  View: e
                                                                                                      .target
                                                                                                      .checked,
                                                                                              },
                                                                                      }
                                                                                    : permission
                                                                            }
                                                                        )
                                                                    // console.log(newPermissions);
                                                                    setPermissionsState(
                                                                        newPermissions
                                                                    )
                                                                }}
                                                            >
                                                                <p className="modal__form-el-checkbox-container-label">
                                                                    {t("read")}
                                                                </p>
                                                            </Checkbox>
                                                            <Checkbox
                                                                disabled={
                                                                    loading
                                                                }
                                                                checked={Search}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const newPermissions =
                                                                        permissionsState.map(
                                                                            (
                                                                                permission
                                                                            ) => {
                                                                                return permission?.name ===
                                                                                    name
                                                                                    ? {
                                                                                          ...permission,
                                                                                          permissionDetail:
                                                                                              {
                                                                                                  ...permission?.permissionDetail,
                                                                                                  Search: e
                                                                                                      .target
                                                                                                      .checked,
                                                                                              },
                                                                                      }
                                                                                    : permission
                                                                            }
                                                                        )
                                                                    // console.log(newPermissions);
                                                                    setPermissionsState(
                                                                        newPermissions
                                                                    )
                                                                }}
                                                            >
                                                                <p className="modal__form-el-checkbox-container-label">
                                                                    {t(
                                                                        "search"
                                                                    )}
                                                                </p>
                                                            </Checkbox>
                                                            <Checkbox
                                                                disabled={
                                                                    loading
                                                                }
                                                                checked={Update}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const newPermissions =
                                                                        permissionsState.map(
                                                                            (
                                                                                permission
                                                                            ) => {
                                                                                return permission?.name ===
                                                                                    name
                                                                                    ? {
                                                                                          ...permission,
                                                                                          permissionDetail:
                                                                                              {
                                                                                                  ...permission?.permissionDetail,
                                                                                                  Update: e
                                                                                                      .target
                                                                                                      .checked,
                                                                                              },
                                                                                      }
                                                                                    : permission
                                                                            }
                                                                        )
                                                                    // console.log(newPermissions);
                                                                    setPermissionsState(
                                                                        newPermissions
                                                                    )
                                                                }}
                                                            >
                                                                <p className="modal__form-el-checkbox-container-label">
                                                                    {t(
                                                                        "update"
                                                                    )}
                                                                </p>
                                                            </Checkbox>
                                                            <Checkbox
                                                                disabled={
                                                                    loading
                                                                }
                                                                checked={Remove}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const newPermissions =
                                                                        permissionsState.map(
                                                                            (
                                                                                permission
                                                                            ) => {
                                                                                return permission?.name ===
                                                                                    name
                                                                                    ? {
                                                                                          ...permission,
                                                                                          permissionDetail:
                                                                                              {
                                                                                                  ...permission?.permissionDetail,
                                                                                                  Remove: e
                                                                                                      .target
                                                                                                      .checked,
                                                                                              },
                                                                                      }
                                                                                    : permission
                                                                            }
                                                                        )
                                                                    // console.log(newPermissions);
                                                                    setPermissionsState(
                                                                        newPermissions
                                                                    )
                                                                }}
                                                            >
                                                                <p className="modal__form-el-checkbox-container-label">
                                                                    {t(
                                                                        "remove"
                                                                    )}
                                                                </p>
                                                            </Checkbox>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {permissions?.length > index + 1 ? (
                                            <div className="modal__crud-divider" />
                                        ) : (
                                            <></>
                                        )}
                                    </Fragment>
                                )
                            }
                        )}
                    </div>

                    <div className="modal__buttons flex">
                        <button
                            onClick={handleCancel ? handleCancel : handleClose}
                            type="button"
                            className="modal__buttons-btn modal__buttons-btn-secondary"
                        >
                            {cancelButtonText || t("cancel")}
                        </button>
                        <Button
                            loading={loading}
                            onClick={() => handleSubmit(permissionsState)}
                            htmlType="button"
                            className="modal__buttons-btn modal__buttons-btn-primary"
                        >
                            {submitText || t("configurePermissions")}
                        </Button>
                    </div>
                </div>
            </BSModal.Body>
        </BSModal>
    )
}
