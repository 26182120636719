export const Check = ({ fill = "#0bb783" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
        >
            <g
                id="vuesax_bulk_tick-circle"
                data-name="vuesax/bulk/tick-circle"
                transform="translate(-748 -188)"
            >
                <g id="tick-circle" transform="translate(748 188)">
                    <path
                        id="Vector"
                        d="M40,20A20,20,0,1,1,20,0,20,20,0,0,1,40,20Z"
                        transform="translate(4 4)"
                        fill={fill}
                        opacity="0.4"
                    />
                    <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M7.155,14.335a1.5,1.5,0,0,1-1.06-.44L.435,8.235a1.5,1.5,0,0,1,2.12-2.12l4.6,4.6L17.435.435a1.5,1.5,0,0,1,2.12,2.12L8.215,13.895A1.5,1.5,0,0,1,7.155,14.335Z"
                        transform="translate(14.005 16.825)"
                        fill={fill}
                    />
                    <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M0,0H48V48H0Z"
                        fill="none"
                        opacity="0"
                    />
                </g>
            </g>
        </svg>
    )
}
