import { getError, axios, getAppModulesConfig, getUserModulesConfig } from "lib"
import { toast } from "react-toastify"
import {
    getAppLevelModules,
    getUserLevelModules,
    setModuleLoading,
} from "store/Slices/moduleSlice"

export const getAppModules = (tenant = "admin") => {
    return async (dispatch) => {
        setModuleLoading(true)
        try {
            const { url, config } = getAppModulesConfig(tenant)
            const res = await axios.get(
                `${process.env.REACT_APP_BASEURL}${url}`,
                config
            )
            // const res = await axios.get(url);
            dispatch(getAppLevelModules(res?.data?.data))
            setModuleLoading(false)
        } catch (e) {
            // toast.error(getError(e));
            setModuleLoading(false)
        }
    }
}

export const getUserModules = ({ id }) => {
    return async (dispatch) => {
        setModuleLoading(true)
        try {
            const { url } = getUserModulesConfig(id)
            const res = await axios.get(url)
            dispatch(getUserLevelModules(res?.data?.data))
            setModuleLoading(false)
        } catch (e) {
            // toast.error(getError(e))
            setModuleLoading(false)
        }
    }
}
