import {
    axios,
    getBillingSettingsByTenantConfig,
    getError,
    getMaintenanceSettingsConfig,
    getSettingsByTenant,
    getSupportSettingsByTenantConfig,
    postMaintenanceSettingsConfig,
    updateBillingSettingsConfig,
    updateSettingsConfig,
    updateSupportSettingsConfig,
    updateTickSettingsConfig,
} from "lib"
import { toast } from "react-toastify"
import {
    getAppSettings,
    getBillingSettings,
    getMaintenanceSettings,
    getMaintenanceSettingsAdmin,
    getMaintenanceSettingsClient,
    getSupportSettings,
    setAppSettingsLoading,
} from "store/Slices"
import {
    checkMaintenanceFail,
    checkMaintenancePending,
    checkMaintenanceSuccess,
} from "store/Slices/settingSlice"

// Get Settings By Tenant
export const getAppSettingsByTenant = (values) => {
    return async (dispatch) => {
        dispatch(setAppSettingsLoading(true))
        try {
            if (values?.isAdmin) {
                // process.env.REACT_APP_BASEURL
                const response = await axios.get(
                    "/api/v1/admin/settings/getsettingswithtenant/admin",
                    {
                        headers: {
                            "Content-type": "application/json",
                            "gen-api-key": process.env.REACT_APP_GEN_APIKEY,
                            tenant: "admin",
                            modulename: "Settings",
                            moduleactionname: "View",
                        },
                    }
                )
                dispatch(getAppSettings(response.data.data))
            } else {
                const { url, config } = getSettingsByTenant("client")
                const response = await axios.get(url, config)
                dispatch(getAppSettings(response.data.data))
            }
        } catch (error) {
            // toast.error(getError(error))
        } finally {
            dispatch(setAppSettingsLoading(false))
        }
    }
}

// Update Settings
export const updateAppSettings = (data) => {
    return async (dispatch) => {
        dispatch(setAppSettingsLoading(true))
        try {
            const { url, config } = updateSettingsConfig("Admin")
            const response = await axios.put(url, data, config)
            // console.log(response)
            if (response.status === 200) {
                const { url, config } = getSettingsByTenant()
                const response = await axios.get(url, config)
                dispatch(getAppSettings(response.data.data))
                toast.success("Settings updated successfully")
            }
        } catch (error) {
            console.log(error)
            toast.error(getError(error))
        } finally {
            dispatch(setAppSettingsLoading(false))
        }
    }
}

// Update Settings
export const updateTickSettings = (data) => {
    return async (dispatch) => {
        dispatch(setAppSettingsLoading(true))
        try {
            const { url, config } = updateTickSettingsConfig("Admin")
            const response = await axios.put(url, data, config)
            // console.log(response)
            if (response.status === 200) {
                const { url, config } = getSettingsByTenant()
                const response = await axios.get(url, config)
                dispatch(getAppSettings(response.data.data))
                toast.success("Settings updated successfully")
            }
        } catch (error) {
            console.log(error)
            toast.error(getError(error))
        } finally {
            dispatch(setAppSettingsLoading(false))
        }
    }
}

// Get Billing Settings
export const getBillingSettingsByTenant = () => {
    return async (dispatch) => {
        dispatch(setAppSettingsLoading(true))

        try {
            const { url, config } = getBillingSettingsByTenantConfig()
            const response = await axios.get(url, config)
            dispatch(getBillingSettings(response.data.data))
        } catch (error) {
            // toast.error(getError(error));
        } finally {
            dispatch(setAppSettingsLoading(false))
        }
    }
}

// Update Billing Settings
export const updateBillingSettings = ({ tenant, data }) => {
    return async (dispatch) => {
        dispatch(setAppSettingsLoading(true))
        try {
            const { url, config } = updateBillingSettingsConfig(tenant)
            const response = await axios.put(url, data, config)
            if (response.status === 200) {
                const { url, config } = getBillingSettingsByTenantConfig()
                const response = await axios.get(url, config)
                dispatch(getBillingSettings(response.data.data))
                toast.success("Settings updated successfully")
            }
        } catch (error) {
            // toast.error(getError(error));
        } finally {
            dispatch(setAppSettingsLoading(false))
        }
    }
}

// Get Maintenance Settings
export const getMaintenanceSettingsByTenant = (tenant) => {
    return async (dispatch) => {
        dispatch(setAppSettingsLoading(true))
        dispatch(checkMaintenancePending(true))

        try {
            const { url, config } = getMaintenanceSettingsConfig(tenant)
            const response = await axios.get(url, config)
            if (tenant.toLowerCase() === "admin") {
                dispatch(getMaintenanceSettingsAdmin(response?.data))
                dispatch(checkMaintenanceSuccess(response?.data))
            } else {
                dispatch(getMaintenanceSettingsClient(response?.data))
            }
        } catch (error) {
            // toast.error(getError(error))
            if (tenant.toLowerCase() === "admin")
                dispatch(checkMaintenanceFail(error))
        } finally {
            dispatch(setAppSettingsLoading(false))
        }
    }
}

// // Update Maintenance Settings
export const updateMaintenanceSettings = ({ data, isAdmin }) => {
    return async (dispatch) => {
        dispatch(setAppSettingsLoading(true))
        try {
            const { url, config } = postMaintenanceSettingsConfig()
            const response = await axios.post(url, data, config)
            if (response.status === 200) {
                const { url, config } = getMaintenanceSettingsConfig()
                const response = await axios.get(url, config)
                dispatch(getMaintenanceSettings(response?.data))
                toast.success("Maintenance Settings updated successfully")
            }
        } catch (error) {
            toast.error(getError(error))
        } finally {
            dispatch(setAppSettingsLoading(false))
        }
    }
}

// Get Support Settings By Tenant
export const getSupportSettingsByTenant = () => {
    return async (dispatch) => {
        dispatch(setAppSettingsLoading(true))
        try {
            const { url, config } = getSupportSettingsByTenantConfig()
            const response = await axios.get(url, config)
            dispatch(getSupportSettings(response.data.data))
        } catch (error) {
            toast.error(getError(error))
        } finally {
            dispatch(setAppSettingsLoading(false))
        }
    }
}

// Update Support Settings
export const updateSupportSettings = ({ id, data }) => {
    return async (dispatch) => {
        dispatch(setAppSettingsLoading(true))
        try {
            const { url, config } = updateSupportSettingsConfig({ id })
            const response = await axios.put(url, data, config)
            if (response.status === 200) {
                const { url, config } = getSupportSettingsByTenant()
                const response = await axios.get(url, config)
                dispatch(getSupportSettings(response?.data))
                toast.success("Settings updated successfully")
            }
        } catch (error) {
            toast.error(getError(error))
        } finally {
            dispatch(setAppSettingsLoading(false))
        }
    }
}
