import { getConfig } from "lib"

// PaymentGateway End-Points
const PaymentGateway = "PaymentGateways"
// Get List of All Payment Gateways
export const getPaymentGatewaysConfig = () => ({
  url: `/api/v1/admin/payment`,
  config: getConfig({ module: PaymentGateway, action: 'View' }),
});

export const listPaymentGatewaysConfig = () => ({
  url: `/api/v1/admin/payment/list`,
  config: getConfig({ module: PaymentGateway, action: 'View' }),
});
// Add Payment Gateway
export const addPaymentGatewayConfig = () => ({
  url: `/api/v1/admin/payment`,
  config: getConfig({ module: PaymentGateway, action: 'Create' }),
});
// Edit Payment Gateway
export const editPaymentGatewayConfig = (id) => ({
  url: `/api/v1/admin/payment/${id}`,
  config: getConfig({ module: PaymentGateway, action: 'Update' }),
});
// Delete Payment Gateway
export const deletePaymentGatewayConfig = ({ id }) => ({
  url: `/api/v1/admin/payment`,
  config: getConfig({ module: PaymentGateway, action: 'Remove' }),
});

export const getUserCardsConfig = (id) => ({
  url: `/api/v1/admin/payment/cards/${id}`,
  config: getConfig({ module: PaymentGateway, action: 'View' }),
})

export const addCardConfig = () => ({
  url: `/api/v1/admin/payment/add-card`,
  config: getConfig({ module: PaymentGateway, action: 'Create' }),
})

export const payConfig = (id) => ({
  url: `/api/v1/admin/payment/pay/${id}`,
  config: getConfig({ module: PaymentGateway, action: 'View' }),
})

export const getClientIdConfig = () => ({
  url: `/api/v1/admin/payment/client-id`,
  config: getConfig({ module: PaymentGateway, action: 'View' }),
})

export const payPalCreateConfig = (id, amount, invoiceId) => ({
  url: `/api/v1/admin/payment/create-order/${id}?amount=${amount}&invoiceId=${invoiceId}`,
  config: getConfig({ module: PaymentGateway, action: 'View' }),
})

export const payPalConfirmConfig = (id, orderId) => ({
  url: `/api/v1/admin/payment/confirm-order/${id}?orderId=${orderId}`,
  config: getConfig({ module: PaymentGateway, action: 'View' }),
})

export const getInstructConfig = () => ({
  url: `/api/v1/admin/payment/instruct`,
  config: getConfig({ module: PaymentGateway, action: 'View' }),
})

export const createTransferConfig = () => ({
    url: `/api/v1/admin/payment/bank`,
    config: getConfig({ module: PaymentGateway, action: 'View' }),
})

export const approveTransferConfig = (id) => ({
    url: `/api/v1/admin/payment/bank/${id}`,
    config: getConfig({ module: PaymentGateway, action: 'View' }),
})

export const getTransfersConfig = () => ({
    url: `/api/v1/admin/payment/bank`,
    config: getConfig({ module: PaymentGateway, action: 'View' }),
})

export const getUserTransfersConfig = (id) => ({
    url: `/api/v1/admin/payment/bank/${id}`,
    config: getConfig({ module: PaymentGateway, action: 'View' }),
})

export const getPayUrlConfig = () => ({
    url: `/api/v1/admin/payment/pay-url`,
    config: getConfig({ module: PaymentGateway, action: 'View' }),
})

export const getRefundConfig = () => ({
    url: `/api/v1/admin/payment/refund`,
    config: getConfig({ module: PaymentGateway, action: 'View' }),
})