export const Close = ({ fill = "#f64e60" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
        >
            <g
                id="vuesax_bulk_close-circle"
                data-name="vuesax/bulk/close-circle"
                transform="translate(-236 -252)"
            >
                <g id="close-circle" transform="translate(236 252)">
                    <path
                        id="Vector"
                        d="M40,20A20,20,0,1,1,20,0,20,20,0,0,1,40,20Z"
                        transform="translate(4 4)"
                        fill={fill}
                        opacity="0.4"
                    />
                    <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M9.275,7.155l4.6-4.6a1.5,1.5,0,1,0-2.12-2.12l-4.6,4.6-4.6-4.6a1.5,1.5,0,0,0-2.12,2.12l4.6,4.6-4.6,4.6a1.509,1.509,0,0,0,0,2.12,1.5,1.5,0,0,0,2.12,0l4.6-4.6,4.6,4.6a1.5,1.5,0,0,0,2.12,0,1.509,1.509,0,0,0,0-2.12Z"
                        transform="translate(16.845 16.845)"
                        fill={fill}
                    />
                    <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M0,0H48V48H0Z"
                        fill="none"
                        opacity="0"
                    />
                </g>
            </g>
        </svg>
    )
}
